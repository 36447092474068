<template>
  <div>
    <AutoPopup />
    <div class="hero-container-small"></div>
    <div class="hero-container">
      <b-container class="a-hero-text-container d-flex mb-sm-5 mb-3">
        <div class="a-hero-text-container-inner w-100">
          <h1 class="mb-0 mt-0 mt-md-5">
            <span class="d-none d-md-inline-flex">{{ $t("Učitelia") }},</span>
            <span class="d-none d-md-inline-flex">{{ $t("pripravme") }}</span>
            <span class="d-inline-flex d-md-none"
              >{{ $t("Učitelia") }}, {{ $t("pripravme") }}</span
            >
            <span class="w-color-primary z-fancy-underline">
              {{ $t("mladých na život!") }}
              <svgFancyUnderline />
            </span>
          </h1>
          <p class="mb-0 mt-sm-5 mt-3">
            {{ $t("Bezplatné praktické video kurzy pre študentov základných") }}
          </p>
          <p>
            {{ $t("a stredných škôl.") }}
          </p>
          <div class="d-flex flex-column align-items-start">
            <b-button
              variant="primary"
              class="a-poppins-btn mt-3"
              :to="primaryButtonLink"
              >{{ primaryButtonText }}</b-button
            >
          </div>
        </div>
      </b-container>
    </div>

    <TopCourses
      v-if="featuredCourses"
      :courses="featuredCourses"
      category="teacher"
    />
    <LatestCourses v-if="latestCourses" :courses="latestCourses" />

    <Benefits :forTeachers="true" />
    <HowItWorks />
    <Testimonials />
    <Stats />
    <Partners />
    <AboutNapisaliONasSection />
    <!-- <a-competition-modal ref="competition-modal"/> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchHomePageData } from "/utils/shared";
import AutoPopup from "/components/AutoPopup.vue";
import * as Sentry from "@sentry/vue";

export default {
  components: {
    AutoPopup,
    svgFancyUnderline: () => import("/assets/icons/fancyUnderline.svg?inline"),
    Stats: () => import("/components/Stats.vue"),
    Testimonials: () => import("/components/Testimonials"),
    Benefits: () => import("/components/Benefits"),
    HowItWorks: () => import("/components/HowItWorks"),
    Partners: () => import("/components/Partners"),
    TopCourses: () => import("/components/TopCourses"),
    LatestCourses: () => import("/components/LatestCourses"),
    AboutNapisaliONasSection: () =>
      import("/components/About/AboutNapisaliONasSection.vue"),
  },

  data() {
    return {
      featuredCourses: null,
      latestCourses: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    primaryButtonLink() {
      if (this.isLoggedIn) {
        return this.isAdmin ? "/triedy" : "/kurzy";
      }

      return "/registracia";
    },
    primaryButtonText() {
      if (this.isLoggedIn) {
        return this.isAdmin
          ? this.$t("Vytvoriť triedu")
          : this.$t("our-courses");
      }

      return this.$t("Registrujte sa zadarmo");
    },
  },

  async mounted() {
    try {
      const { featuredCourses, latestCourses } = await fetchHomePageData(
        "teacher"
      );

      this.featuredCourses = featuredCourses;
      this.latestCourses = latestCourses;
    } catch (error) {
      Sentry.captureException(error);
      this.featuredCourses = { error: this.$t("unable-to-load-courses") };
    }
  },
};
</script>
<style lang="scss" scoped>
.hero-container-small {
  background-image: url("/backgroundTeachers.png");
  background-position: -15rem center;

  @media only screen and (min-width: 360px) {
    background-position: right top;
  }
  @media only screen and (min-width: 445px) {
    height: 25rem;
    background-size: 46.875rem;
  }
  @media only screen and (min-width: 600px) {
    height: 28.125rem;
    background-size: 53.125rem;
  }
}
.hero-container {
  @media only screen and (min-width: 768px) {
    background-image: url("/backgroundTeachers.png");
  }

  h1 {
    display: inline-flex;
    flex-direction: column;

    span {
      margin-right: 0.625rem;

      &:last-child {
        margin-right: 0;
      }
      @media only screen and (min-width: 768px) {
        align-self: flex-start;
        margin-right: 0;
      }
    }
  }
  button {
    height: 3.75rem;
    width: 12.5rem;
  }
}
</style>
